import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import { Flex, Box, Heading, Button, Link } from "theme-ui";
import { useThemeUI } from "theme-ui";

const Nosotros = () => {
  const { theme } = useThemeUI();
  console.log("Desde nosotros ===>", theme.variants.link);
  return (
    <>
      <Seo title="Nosotros" description="Chocolate y productos regionales" />
      <Heading
        as="h1"
        sx={{ fontSize: [30, 36, 42], textAlign: "center" }}
        my={4}
      >
        De lo nuestro... lo mejor
      </Heading>
      <Flex
        pt={3}
        px={2}
        mx="auto"
        sx={{ flexDirection: ["column", null, "row"], maxWidth: 1300 }}
      >
        <Box px={3}>
          <article>
            <h2>Acerca de Nosotros</h2>
            <p>
              Somos una empresa familiar que inicó con la intención de rescatar
              las recetas tracionales de nuestros abuelos en Chiapas.
            </p>
            <p>
              Al darnos cuenta de la riqueza de nuestro pasado culinario, nos
              dimos a la tarea de enfocarnos en el Cacao, sus beneficios y su
              transformación en deliciosos y nutritivos productos, tales como el
              chocolate de mesa y bebidas típicas basadas en esta ancestral
              semilla.
            </p>
            <Button mb={[3, null, null]}>
              <Link
                as={GatsbyLink}
                to="/contacto"
                sx={{
                  color: "background",
                  ":hover,:focus,.active": {
                    color: "primary",
                    textDecoration: "none",
                  },
                }}
              >
                Contacto
              </Link>
            </Button>
          </article>
        </Box>

        <Box px={3}>
          <StaticImage
            src="../images/acerca.jpg"
            alt="Productos Tatik Adelino"
            className="about-img"
            placeholder="blurred"
            style={{ borderRadius: "5px" }}
          />
        </Box>
      </Flex>
    </>
  );
};

export default Nosotros;
